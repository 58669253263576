<template>
    <div>
        <Pop ref="pop" position="Right">
            <div :style="pageStyle" class="dateContent" slot="popContent">
                <div class="dateHead">
                    <div class="dateBackRow">
                        <Svgs @click.native="openPop" color="#000" name="iconfanhui"></Svgs>
                    </div>
                    <div class="dateTitle">{{title || '会议时间'}}</div>
                    <div class="dateRange">
                        <Svgs @click.native="openPop" class="dateRiqiIcon" :color="color" name="iconrili"></Svgs>
                        <span v-if="starts.year">{{starts.year}}.{{starts.monthZ}}.{{starts.dayZ}} {{starts.weekZ}}</span>
                        <span v-if="ends.year" class="dateSeparator">-</span>
                        <span v-if="ends.year">{{ends.year}}.{{ends.monthZ}}.{{ends.dayZ}} {{ends.weekZ}}</span>
                        <span v-if="!starts.year">暂未选择</span>
                    </div>
                </div>
                <div class="calendarContent">
                    <ul class="calendarWeek">
                        <li class="calendarWeekEnd">六</li>
                        <li>一</li>
                        <li>二</li>
                        <li>三</li>
                        <li>四</li>
                        <li>五</li>
                        <li class="calendarWeekEnd">日</li>
                    </ul>

                    <div class="calendarScroll" @scroll="scrollCalendar">
                        <div class="calendarList" v-for="(month,index) in calendarList" :key="index">
                            <div class="calendarHead">{{month[0].year}} 年 {{month[0].month}} 月</div>
                            <ul class="calendarWeekContent">
                                <li style="visibility: hidden;" v-for="(days,inds) in month[0].weekBlank"
                                    :key="inds+month[0].year">1
                                </li>
                                <li @click="chooseDay(day)" :class="{
                                        'calendarWeekEnd':(day.week==6||day.week==0),
                                        'calendarStart':day.checked=='start',
                                        'calendarEnd':day.checked=='end',
                                        'calendarOver':day.isOver,
                                        'calendarOnly':day.isOnly,
                                        'calendarLimit':day.isLimit
                                    }"
                                    v-for="(day,ind) in month"
                                    :key="ind">
                                    <span v-if="day.now" class="calendarNow">今天</span>
                                    <span v-if="!day.now"> {{day.day}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="calendarBtn">
                    <Btn btnClass="normalBtn" @callBack="onOk">
                        <span slot="btnContent">确定</span>
                    </Btn>
                </div>
            </div>
        </Pop>
    </div>
</template>

<script>
    export default {
        name: "datePicker",
        props: [
            'start', //开始日期限制 no为不限制 格式'2020-07-02' (默认限制到今天)
            'end',//结束日期限制  no为不限制 格式'2020-07-02' (默认不限制)
            'connect', //日期连接符 (默认'-')
            'rangeConnect',//开始日期结束日期连接符 (默认'~')
            'title',//日历标题 (默认'会议时间')
            'isRadio',//是否单选 (默认区间选择)
            'noDefault'//是否默认选中今天 (默认 false选中  true 不选中)
        ],
        data() {
            return {
                calendarList: [],//日历数组
                starts: {},//开始日期
                ends: {},//结束日期
                color: this.setColor(),//主题颜色
            }
        },
        created() {
            this.setStyle();
            this.init();
        },
        methods: {
            // 初始化日历(2个月)
            init() {
                let now = this.getFullDate(new Date());
                this.getCalendar(now.year, now.month);
                if (now.month < 12) {
                    this.getCalendar(now.year, now.month + 1);
                } else {
                    this.getCalendar(now.year, 1);
                }
            },
            //选择日期\日期区间
            chooseDay(day) {
                //日期被限制直接返回
                if (day.isLimit) {
                    return;
                }
                //循环清空已包含和唯一日期
                for (let i = 0; i < this.calendarList.length; i++) {
                    let calendar = this.calendarList[i];
                    for (let j = 0; j < calendar.length; j++) {
                        calendar[j].isOver = false;
                        calendar[j].isOnly = false;
                    }
                }
                //是否为单选日历
                if (this.isRadio) {
                    day.isOnly = true;
                    this.starts = day;
                } else {
                    //已经选择开始结束日期
                    if (this.starts.year && this.ends.year) {
                        this.starts.checked = false;
                        this.starts = {};
                        this.ends.checked = false;
                        this.ends = {};
                        day.checked = 'start';
                        day.isOnly = true;
                        this.starts = day;
                    } else if (this.starts.year) {
                        //只选择了开始日期（开始选择结束日期）
                        let dayTime = new Date((day.year + '/' + day.month + '/' + day.day));
                        let starts = new Date((this.starts.year + '/' + this.starts.month + '/' + this.starts.day));
                        if (dayTime < starts) {
                            //若选择的结束日期小于开始日期则调换开始日期与结束日期
                            this.starts.checked = 'end';
                            this.ends = this.starts;
                            day.checked = 'start';
                            this.starts = day;
                        } else if (dayTime.getTime() == starts.getTime()) {
                            //若选择两次的日期相同则默认为只选择了一天（开始日期结束日期相同）
                            day.isOnly = true;
                            day.checked = 'end';
                            this.ends = day;
                        } else {
                            //正常选中结束日期
                            day.checked = 'end';
                            this.ends = day;
                        }
                        let time = 0;
                        //选择区间完毕 循环确认包含的日期
                        for (let i = 0; i < this.calendarList.length; i++) {
                            let calendar = this.calendarList[i];
                            for (let j = 0; j < calendar.length; j++) {
                                let calTime = new Date((calendar[j].year + '/' + calendar[j].month + '/' + calendar[j].day));
                                let nowStart = new Date((this.starts.year + '/' + this.starts.month + '/' + this.starts.day));
                                let nowEnd = new Date((this.ends.year + '/' + this.ends.month + '/' + this.ends.day));
                                if (calTime > nowStart && calTime < nowEnd) {
                                    time += 20;
                                    var sUsrAg = window.navigator.userAgent;
                                    if (sUsrAg.indexOf('Firefox') > -1) {
                                        calendar[j].isOver = true;
                                    } else {
                                        setTimeout(function () {
                                            calendar[j].isOver = true;
                                        }, time);
                                    }
                                }
                                if (calTime >= nowEnd) {
                                    return;
                                }
                            }
                        }
                    } else {
                        //正常选择开始日期
                        day.checked = 'start';
                        day.isOnly = true;
                        this.starts = day;
                    }
                }
            },
            //生成日历
            getCalendar(year, month, sort) {
                let even = [4, 6, 9, 11];
                let day = 31;
                if (even.indexOf(month) != -1) {
                    day = 30;
                } else if ((((year % 4) == 0) && ((year % 100) != 0) || ((year % 400) == 0)) && month == 2) {
                    day = 29;
                } else if (month == 2) {
                    day = 28;
                }
                let arr = [];
                //循环生成日历每一天
                for (let i = 1; i <= day; i++) {
                    let forDay = new Date((year + '/' + month + '/' + i));
                    let week = forDay.getDay();//获取星期几
                    let today = (new Date(this.parseDate(new Date()))).getTime();
                    let now = false;
                    if (forDay.getTime() == today) {
                        now = true;
                    }
                    let weekZ = '周';
                    switch (week) {
                        case 0:
                            weekZ += '日';
                            break;
                        case 1:
                            weekZ += '一';
                            break;
                        case 2:
                            weekZ += '二';
                            break;
                        case 3:
                            weekZ += '三';
                            break;
                        case 4:
                            weekZ += '四';
                            break;
                        case 5:
                            weekZ += '五';
                            break;
                        case 6:
                            weekZ += '六';
                            break;
                    }
                    let isLimit = false;
                    if (this.start != 'no') {
                        //限制开始日期为今天之后或指定日期
                        let startDay = new Date((new Date().getTime() - 86400000));
                        if (this.start) {
                            startDay = new Date(this.start);
                        }
                        //若日期小于其实日期则限制不可选
                        if (startDay > forDay) {
                            isLimit = true
                        }
                    }
                    if (this.end != 'no' && this.end) {
                        //限制结束日期为指定时间之前
                        let endDay = new Date(this.end);
                        if (endDay < forDay) {
                            isLimit = true
                        }
                    }
                    let weekBlank = [];
                    if (i == 1) {
                        for (let j = 0; j < week; j++) {
                            weekBlank.push(j);
                        }
                    }
                    arr.push({
                        year: year,
                        weekBlank: weekBlank,
                        month: month,
                        monthZ: this.parseNum(month),//加零月份
                        day: i,
                        dayZ: this.parseNum(i),//加零日期
                        week: week,//星期数字0~6
                        weekZ: weekZ,//星期中文
                        checked: false,//选中状态false（未选中）  start(起始)  end(结束)
                        isOver: false,//是否包含
                        isOnly: false,//是否只选中一天
                        now: now,//是否是今天
                        isLimit: isLimit,//是否被限制不可选
                    });
                    if (now&&!this.noDefault) {
                        this.chooseDay(arr[arr.length - 1]);
                    }
                }
                if (sort) {
                    this.calendarList.unshift(arr);
                } else {
                    this.calendarList.push(arr);
                }
            },
            //日历滚动
            scrollCalendar(e) {
                let dom = e.path[0];
                let lastYear = this.calendarList[this.calendarList.length - 1][0].year;
                let lastMonth = this.calendarList[this.calendarList.length - 1][0].month;
                let firstYear = this.calendarList[0][0].year;
                let firstMoth = this.calendarList[0][0].month;
                if (dom.scrollTop + dom.offsetHeight == dom.scrollHeight) {
                    if (lastMonth < 12) {
                        lastMonth += 1;
                    } else {
                        lastMonth = 1;
                        lastYear += 1;
                    }
                    this.getCalendar(lastYear, lastMonth);
                } else if (dom.scrollTop == 0) {
                    if (firstMoth > 1) {
                        firstMoth -= 1;
                    } else {
                        firstMoth = 12;
                        firstYear -= 1;
                    }
                    this.getCalendar(firstYear, firstMoth, true);
                }
            },
            //开启\关闭弹框
            openPop() {
                this.$refs.pop.changePop();
            },
            //设置全屏
            setStyle() {
                let height = window.innerHeight + 'px';
                this.pageStyle = {
                    height: height,
                    background: '#fff'
                }
            },
            //获取日期年月日
            getFullDate(date) {
                let newDate = {
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    day: date.getDate()
                }
                return newDate;
            },
            //格式化日期
            parseDate(date) {
                return date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()
            },
            //数字小于10加0
            parseNum(num) {
                if (num < 10) {
                    num = '0' + num;
                }
                return num;
            },
            //确认选择
            onOk() {
                if (!this.starts.year) {
                    this.$store.commit('setValueAlert', '请选择日期！');
                    return;
                }
                let connect = this.connect || '-';
                let startTxt = this.starts.year + connect + this.starts.monthZ + connect + this.starts.dayZ;
                let date = {};
                if (this.isRadio) {
                    date = {
                        day: this.starts,
                        dayTxt: startTxt,
                        calendarList: this.calendarList,
                    }
                } else {
                    if (!this.ends.year) {
                        this.ends = this.starts;
                    }
                    let rangeConnect = this.rangeConnect || '~';
                    let endTxt = this.ends.year + connect + this.ends.monthZ + connect + this.ends.dayZ;
                    date = {
                        start: this.starts,
                        end: this.ends,
                        startTxt: startTxt,
                        endTxt: endTxt,
                        calendarList: this.calendarList,
                        range: startTxt + rangeConnect + endTxt
                    }
                }
                this.$emit('setDate', date);
                this.openPop();
            },
            //主题颜色
            setColor() {
                // let skin = sessionStorage.getItem('skin');
                // if (skin) {
                //     return base[skin + 'PrimaryColor'];
                // } else {
                //     return base.primaryColor
                // }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "DatePicker";
</style>